import data from "./curveData.js"
import selection from "../../../../components/selection.vue";

export default {
	name: "curve",
	components: {
		selection
	},
	props: {
		switchNavMenu: {
			type: Function,
			required: true
		}
	},
	data,
	created() {
		const D = new Date();
		let before = new Date(D.getTime() - 86400000 * 7).format('yyyy/MM/dd') + ' 00:00:00'
		this.form.timeS = new Date(before).getTime();
		let now = new Date(D.getTime()).format('yyyy/MM/dd') + ' 23:59:59';
		this.form.timeE = new Date(now).getTime();
	},
	computed: {
		organization: {
			get() {
				if (this.searchType.id === 1) {
					return this.enterprise
				} else {
					return this.project
				}
			},
			set(val) {
				return val;
			}
		}
	},
	methods: {
		//翻页组件触发分页回调
		currentPageChange(type) {
			if (type === 'curve') {
				this.getDeviceFile();
			}
		},
		//按钮组件点击事件回调
		buttonCallback(type) {
			if (type === 'addList') {
				this.selectFileData();
			} else if (type === 'createChart') {
				this.createChart();
			} else if (type === 'clearAll') {
				this.$popover('您确定要清除全部数据吗？', e => {
					if (e) this.checkedFileList = [];
				})
			} else if (type === 'search') {
				this.item.time = true;
				this.getDeviceFile();
			} else if (type === 'addData') {
				for (let item in this.addData) {
					this.addData[item] = ''
				}
				this.addData["fileName"] = "wenjianming"
				this.addData["pointName"] = "cedianming"
				this.addData["alias"] = "alias"

				$('.layerContainerBox').fadeIn(300).find('.addData').show().siblings().hide();
			}
		},
		//创建chart
		createChart() {
			let series = {
				data: [],
				type: 'line',
				smooth: true
			}
			let data = {
				v: [],
				r: [],
				q: []
			}
			data.v.length = 0;
			data.r.length = 0;
			for (let list of this.checkedFileList) {
				data.q.push(list.Q);
				data.r.push(list.R);
				data.v.push(this.toFixed(list[this.value + 'max'], 4));
			}

			let value = this.Sadowski.calculate(data);
			if (isNaN(value.csk) || isNaN(value.csa) || value.csk < 0 || value.csa < 0) {
				this.$messages('输入数据有误，统计无效！', 'warning');
				return false
			}
			let xdata = [],
				ydata = [];
			let csa = value.csa;
			let csb = value.csb;

			for (let i = 0; i < 101; i++) {
				let aa = Math.log(0.1) - i * Math.log(0.1) / 100.0;
				let bb = csb + csa * aa;
				xdata.push(this.toFixed(Math.exp(aa), 4))
				ydata.push(this.toFixed(Math.exp(bb), 4));
			}

			this.condition.k = this.toFixed(value.csk, 4);
			this.condition.a = this.toFixed(value.csa, 4);

			let option = _.cloneDeep(this.echart.options);
			option.xAxis.data = xdata;
			option.yAxis.min = 'dataMin';
			option.yAxis.max = 'dataMax';
			option.yAxis.max = ydata.max();
			series.data = ydata;
			option.series.push(series);

			// let session = this.$session.get();
			// session.analysis = {};
			// session.analysis.curve = option;
			// this.$session.set(session);
			this.drawChart(option);
		},
		//选择企业或者项目
		selectOrganization() {
			this.form.station = '';
		},
		//选择搜索类型
		selectType(id, name) {
			this.form.organization = null;
			this.searchType = {
				id: id,
				name: name
			}
			this.placeholder = name;
		},
		//选中设备列表
		selectDevice(p) {
			let status = true;
			p.selected = !p.selected;

			if (p.selected) {
				if (this.checkedFile.length > 0) {
					for (let i = 0; i < this.checkedFile.length; i++) {
						if (this.checkedFile[i].id === p.id) {
							status = false;
							break;
						}
					}
					if (status) {
						this.checkedFile.push(p);
					}
				} else {
					this.checkedFile.push(p)
				}
			} else {
				for (let i = 0; i < this.checkedFile.length; i++) {
					if (this.checkedFile[i].id === p.id) {
						this.checkedFile.splice(i, 1);
					}
				}
			}

			if (this.checkedFile.length > 0) {
				if (this.checkedFile.length === this.item.rows.length) {
					this.checkAllStatus = 2;
					this.checkAll = true;
				} else if (this.checkedFile.length < this.item.rows.length) {
					this.checkAllStatus = 1;
				}
			} else {
				this.checkAllStatus = 0;
				this.checkAll = false;
			}
		},
		//全选当前页
		selectAllDevice() {
			this.checkedFile.length = 0;
			this.checkAll = !this.checkAll;
			for (let i = 0; i < this.item.rows.length; i++) {
				this.item.rows[i].selected = this.checkAll;
				if (this.checkAll) {
					this.checkAllStatus = 2;
					this.checkedFile.push(this.item.rows[i]);
				} else {
					this.checkedFile.length = 0;
					this.checkAllStatus = 0;
				}
			}
		},
		//获取全选checkbox状态
		getCheckedStatus(num) {
			switch (num) {
				case 0:
					return 'checkbox'
				case 1:
					return 'checkbox is-indeterminate'
				case 2:
					return 'checkbox is-checked'
			}
		},
		//是否显示当前测点
		showStation(item) {
			if (this.searchType.id === 1) {
				if (this.form.organization) {
					return this.form.organization === item.enterprise;
				} else {
					return true
				}
			} else {
				if (this.form.organization) {
					return this.form.organization === item.projectID;
				} else {
					return true
				}
			}
		},
		//获取树
		getTree() {
			// this.tree = this.$session.get().userTree;
			if (this.tree && this.tree.length) {
				if (this.tree[0].id === -1) {
					this.tree.splice(0, 1);
				}
				this.tree.splice(0, 0, {
					id: -1,
					name: '全部',
					children: []
				});
				this.recursionTree(this.tree, [3, 4]);
			}
			this.getDeviceType();
		},
		//获取设备类型
		getDeviceType() {
			// this.axios.get(`all`).then(res => {
			//   if (res.status) {
			//     this.deviceType = res.data;
			//     // this.getDeviceFile();
			//   } else {
			//     this.$messages(res.message, 'error')
			//   }
			// });
		},
		//获取设备文件列表
		getDeviceFile2() {
			const {
				page,
				size
			} = this.item;
			const time = this.form.time;
			const data = {};
			data.sTime = this.form.timeS;
			data.eTime = this.form.timeE;
			data.page = page;
			data.size = size;

			data.pointLineId = null;
			data.dPId = this.form.type;
			data.orderRule = 2;
			let cascader = this.$refs.myCascader.getSelect();
			if (cascader.type === 0) {
				data.companyId = cascader.id;
			} else if (cascader.type === 1) {
				data.projectId = cascader.id;
			} else if (cascader.type === 3) {
				data.pointId = cascader.id;
			}

			this.checkAll = false;
			this.checkAllStatus = 0;
			this.checkedFile.length = 0;
			this.axios.post(`/data/sjfx/file/azcdcx`, data).then(res => {
				if (res.status) {
					for (let i in res.data.list) {
						res.data.list[i].selected = false;
					}
				}
				this.setRowsData(res, this.item, 'list');
			})
		},
		//获取设备文件列表
		getDeviceFile() {
			const {
				page,
				size
			} = this.item;
			const data = {};
			data.page = page;
			data.size = size;
			data.orderRule = 2;

			if (this.item.time) {
				data.sTime = this.form.timeS;
				data.eTime = this.form.timeE;

				if (data.sTime > data.eTime) {
					this.$messages('开始时间大于结束时间，请重新选择', 'warning', 3000)
					return false
				}
			}

			data.pointLineId = null;
			data.dPId = this.form.type;

			let cascader = this.$refs.myCascader.getSelect();
			if (cascader.type === 0) {
				data.companyId = cascader.id;
			} else if (cascader.type === 1) {
				data.projectId = cascader.id;
			} else if (cascader.type === 3) {
				data.pointId = cascader.id;
			}

			this.checkAll = false;
			this.checkAllStatus = 0;
			this.checkedFile.length = 0;
			this.checkedFileList.length = 0;
			this.axios.post(`/data/sjfx/file/azcdcx`, data).then(res => {
				if (res.status) {
					for (let i in res.data.list) {
						res.data.list[i].selected = false;
					}
				}
				this.setRowsData(res, this.item, 'list');
			})
		},
		//选择文件添加到曲线拟合列表
		selectFileData() {
			if (this.checkedFileList.length === 0) {
				this.checkedFileList = _.cloneDeep(this.checkedFile);

			} else {
				for (let list of this.checkedFileList) {
					for (let i = 0; i < this.checkedFile.length; i++) {
						if (list.id === this.checkedFile[i].id) {
							this.checkedFile.splice(i, 1);
						}
					}
				}

				let fileList = [...this.checkedFileList, ...this.checkedFile];
				this.checkedFileList = _.cloneDeep(fileList);
			}

			for (let i = 0; i < this.checkedFileList.length; i++) {
				let list = this.checkedFileList[i];
				list.selected = false;
				list.alias = list.fileName;
				list.Q = 20;
				list.R = 20 + i * 10;
				let x = this.toFixed(list.xmax, 4) * 10000,
					y = this.toFixed(list.xmax, 4) * 10000,
					z = this.toFixed(list.xmax, 4) * 10000;
				list.summax = (x + y + z) / 10000;
				list.summaxTime = 0;
			}
		},
		drawChart(option) {
			console.log('(#→⌒→)', 'option: ', option)

			var dom = document.getElementById("chartContainer");
			var myChart = this.echarts.init(dom);
			// let session = this.$session.get();
			// session.analysis = {};
			// session.analysis.curve = option;
			// this.$session.set(session);
			if (option && typeof option === "object") {
				myChart.clear();
				myChart.setOption(option, true);
			}
		},
		//清除数据
		clearData(index) {
			this.checkedFileList.splice(index, 1);
		},
		//联级选择回调
		changeValue(node) {
			this.item.page = 1;
			this.item.time = false;
			this.getDeviceFile();
		},
		//双击修改值
		changeFileValue(e) {
			let self = $(e.currentTarget);
			if (!self.hasClass('show')) {
				self.addClass('show');
				self.find('input').focus();
				self.parent().siblings().find('li').removeClass('show');
			}
		},
		//input失去焦点时隐藏input
		hiddenValue(e) {
			let self = $(e.currentTarget);
			self.parent().removeClass('show');
			this.$forceUpdate();
		},
		//弹出框按钮点击回调事件
		layerBtnClick(type) {
			if (type === 'dataCon') {
				// if(fileName){

				// }
				let data = {
					fileName: new Date().format('yyyy-MM-dd hh:mm:ss').split('-').join("").split(":").join("")
						.replace(" ", "") + ".bin",
					xmax: this.addData.V,
					ymax: this.addData.V,
					zmax: this.addData.V,
					summax: this.addData.V,
					xmaxTime: 0,
					ymaxTime: 0,
					zmaxTime: 0,
					summaxTime: 0,
					Q: this.addData.Q,
					R: this.addData.R,
					//   companyName:"BP-90",
					//   pointName:"",
					//   projectName:"",
					//   operator:"",
					//   filename:"20210113161326.bin"
				}
				this.checkedFileList.push(data);
				$('.layerContainerBox').fadeOut(300);
			}
		}
	},
	mounted() {
		this.drawChart(_.cloneDeep(this.echart.options));
		this.item.time = true;
		this.getTree();
	},
	beforeDestroy() {},
	watch: {}
}
