export default () => {
  return {
    container: [],
    images: [],
    form: {
      name: '爆破安全药量预测报告',
      project: '爆破振动检测项目',
      enterprise: '四川交博环境检测有限公司',
      operator: '张薇薇',
      remarks: '备注',
      K: 0,
      a: 0,
      Q: 0,
      R: 0,
      safetyQ: 0,
      safetyR: 0,
      CVal: 1.2,
      dataLength: 0,
      images: '',
      curve: '',
      left: '',
      right: '',
      date: []
    },
    tableData: [
      // {
      //   filename: '2020/04/13 14:37:57',
      //   qVal: '20',
      //   rVal: '20',
      //   vVal: '1.5523',
      //   tVal: '0.5482'
      // }
    ]
  }
}
