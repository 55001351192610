import data from "./forecastData.js";
import reportLayer from "./reportLayer";
import forecastReport from "../../../word/forecastReport/forecastReport.vue";
import calculateLayer from "./calculateLayer";

export default {
  name: "forecast",
  components: { reportLayer, forecastReport, calculateLayer },
  props: {
    switchNavMenu: {
      type: Function,
      required: true
    }
  },
  data,
  created() {},
  watch: {
    value(val) {
      this.selectType = Number(val);
      setTimeout(e => {
        if (this.selectType === 1) {
          this.drawLeft(_.cloneDeep(this.echart.options));
          this.drawRight(_.cloneDeep(this.echart.options));
        } else {
          this.drawOuter(_.cloneDeep(this.echart.options));
        }
      }, 100);
    }
  },
  computed: {},
  methods: {
    //按钮组件点击事件回调
    buttonCallback(type) {
      let option = _.cloneDeep(this.echart.options);
      option.yAxis.min = "dataMin";
      option.yAxis.max = "dataMax";
      if (type === "forecastLeft") {
        this.calculateDataLeft();
      } else if (type === "forecastRight") {
        this.calculateDataRight();
      } else if (type === "forecastOther") {
        this.calculateDataOther();
      } else if (type === "showReportLayer") {
        let file = this.$parent.getSelectedFile();
        if (file) {
          this.$refs.reportLayer.showLayer(file);
        }
      } else if (type === "calculate") {
        this.$refs.calculateLayer.showLayer(this.echart.K, this.echart.a);
      }
    },
    calculateDataLeft() {
      let Q = this.echart.left.q;
      let V = [],
        R = [];
      let option = _.cloneDeep(this.echart.options);

      for (let i = this.echart.left.r1; i < this.echart.left.r2 + 1; i++) {
        R.push(i);
        V.push(
          this.toFixed(
            this.Sadowski.calculateV(Q, i, this.echart.K, this.echart.a),
            4
          )
        ); //Q, R, K, A
      }

      option.xAxis.data = R;
      option.yAxis.min = "dataMin";
      option.yAxis.max = "dataMax";
      option.series.push({
        data: V,
        type: "line",
        smooth: true
      });
      option.xAxis.name = "距离R（m）";

      this.drawLeft(option);
    },
    //药量固定，距离递增
    drawLeft(option) {
      var dom = document.getElementById("chartLeft");
      var myChart = this.echarts.init(dom);

      if (option && typeof option === "object") {
        myChart.clear();
        myChart.setOption(option, true);
        this.echart.left.dom = myChart;
      }
    },
    calculateDataRight() {
      let R = this.echart.right.r;
      let V = [],
        Q = [];
      let option = _.cloneDeep(this.echart.options);

      for (let i = this.echart.right.q1; i < this.echart.right.q2 + 1; i++) {
        Q.push(i);
        V.push(
          this.toFixed(
            this.Sadowski.calculateV(i, R, this.echart.K, this.echart.a),
            4
          )
        ); //Q, R, K, A
      }

      option.xAxis.data = Q;
      option.yAxis.min = "dataMin";
      option.yAxis.max = "dataMax";
      option.series.push({
        data: V,
        type: "line",
        smooth: true
      });
      option.xAxis.name = "药量Q（Kg）";
      this.drawRight(option);
    },
    //距离固定，药量递增
    drawRight(option) {
      var dom = document.getElementById("chartRight");
      var myChart = this.echarts.init(dom);

      if (option && typeof option === "object") {
        myChart.clear();
        myChart.setOption(option, true);
        this.echart.right.dom = myChart;
      }
    },
    calculateDataOther() {
      let v = this.echart.outer.v;
      let Q = [],
        R = [];
      let option = _.cloneDeep(this.echart.options);

      for (let i = this.echart.outer.r1; i < this.echart.outer.r2 + 1; i++) {
        R.push(i);
        Q.push(
          this.toFixed(
            this.Sadowski.calculateQ(v, i, this.echart.K, this.echart.a),
            4
          )
        ); //Q, R, K, A
      }

      option.xAxis.data = R;
      option.yAxis.min = "dataMin";
      option.yAxis.max = "dataMax";
      option.series.push({
        data: Q,
        type: "line",
        smooth: true
      });
      this.drawOuter(option);
    },
    //计算药量和距离的关系图
    drawOuter(option) {
      var dom = document.getElementById("chartOther");
      var myChart = this.echarts.init(dom);

      option.xAxis.name = "距离R（m）";
      option.yAxis.name = "药量Q（Kg）";

      if (option && typeof option === "object") {
        myChart.clear();
        myChart.setOption(option, true);
      }
    },
    //初始化chart
    initChart(condition) {
      this.echart.K = condition.k;
      this.echart.a = condition.a;
      setTimeout(e => {
        this.drawLeft(_.cloneDeep(this.echart.options));
        this.drawRight(_.cloneDeep(this.echart.options));
      }, 100);
    },
    //弹出框点击按钮回调事件
    layerBtnClick(type) {
      if (type === "reportCon") {
        this.selectedFile = this.$parent.getSelectedFile();
        this.selectedFile.name = this.$refs.reportLayer.form.name;
        this.selectedFile.projectName = this.$refs.reportLayer.form.project;
        this.selectedFile.companyName = this.$refs.reportLayer.form.enterprise;
        this.selectedFile.operator = this.$refs.reportLayer.form.operator;
        this.selectedFile.remarks = this.$refs.reportLayer.form.remarks;
        this.$refs.forecastReport.getChartImage(this.selectedFile);
      }
    }
  },
  mounted() {
    // setTimeout(e => {
    //   this.drawLeft(_.cloneDeep(this.echart.options));
    //   this.drawRight(_.cloneDeep(this.echart.options));
    // }, 100)
  },
  beforeDestroy() {}
};
