<template>
  <div class="reportLayer">
    <div class="basics infoItem">
      <div class="infoContent">
        <el-scrollbar :native="false" :noresize="false" tag="div">
          <label class="list">
            <span>报表名称</span>
            <input type="text" v-model="form.name" placeholder="请输入项目名称" autocomplete="off">
          </label>
          <label class="list">
            <span>项目名称</span>
            <input type="text" v-model="form.project" placeholder="请输入项目名称" autocomplete="off">
          </label>
          <label class="list">
            <span>企业名称</span>
            <input type="text" v-model="form.enterprise" placeholder="请输入测点编号" autocomplete="off">
          </label>
          <label class="list">
            <span>操作人员</span>
            <input type="text" v-model="form.operator" placeholder="请输入操作人员" autocomplete="off">
          </label>
          <label class="list">
            <span>备注</span>
            <input type="text" v-model="form.remarks" placeholder="请输入备注" autocomplete="off">
          </label>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "reportLayer",
    data() {
      return {
        form: {
          name: '同爆次爆破振动监测统计结果',
          project: '爆破振动检测项目',
          enterprise: '四川交博环境检测有限公司',
          operator: '张薇薇',
          remarks: '',
          Cval: 1.2
        },
      }
    },
    computed: {},
    created() {
    },
    methods: {
      getFormData() {
        return this.form;
      },
      //显示弹窗
      showLayer(data) {
        this.form.operator = data.operator ? data.operator : null;
        this.form.enterprise = data.companyName;
        this.form.project = data.projectName;
        $('.layerContainerBox').fadeIn(300).find('.report').show().siblings().hide();
      },
    },
  }
</script>

<style scoped lang="scss">
  .reportLayer {
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    overflow: hidden;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;

    .infoItem {
      flex: 1 1 auto;
      display: flex;
      flex-flow: column;
      width: 100%;

      .title {
        flex: 0 0 20px;
        line-height: 20px;
        color: #3B9EFF;
      }

      .infoContent {
        flex: 1 1 auto;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-flow: column;


        .list {
          //width: 300px;
          line-height: 50px;
          float: left;

          span {
            float: left;
            width: 50px;
            text-align: right;
            margin-right: 10px;
            color: #4A4A4A;
          }

          input {
            width: 500px;
            height: 28px;
            padding: 0 10px;
            box-sizing: border-box;
            border: 1px solid #E5E5E5;
            font-size: 12px;
          }
        }

        /*.list:nth-child(2n) {*/
        /*  margin-left: 20px;*/
        /*}*/

        .list:last-child {
          margin-bottom: 10px;
        }

        .list.end {
          width: 100%;
          overflow: hidden;
          margin-left: 0;

          input {
            width: 50px;
          }

          .el-radio {
            min-width: 150px;
          }

          .el-select {
            .el-input__inner {
              width: 475px;
            }
          }

          ul {
            border: 1px solid #ccc;
            background-color: #f5f5f5;
            overflow: hidden;
            height: 50px;

            li {
              float: left;
              width: 25%;
              height: 50px;
              line-height: 25px;
              border-right: 1px solid #ccc;
              box-sizing: border-box;
              text-align: center;
            }

            li:first-child {
              line-height: 50px;
            }

            li:last-child {
              border-right: 0;
            }
          }
        }
      }
    }
  }
</style>
