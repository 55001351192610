<template>
	<!-- 多级选择框 -->
	<div class="selection-box" id="selectionPage">
		<el-input v-model="selectText" :placeholder="placeholder" @focus="selectShow" @blur="selectHide" @clear="getAllData" clearable></el-input>
		<div class="selection-card" @mouseleave="onemoveIt" @mouseenter="mouse = true">
			<el-card class="box-card">
				<vue-scroll>
					<div class="card-cell pointer" @click="oneClick($event, {})" @mouseenter="oneMoveIn($event, {})">全部</div>
					<div v-for="(i, index) in selectList" :key="index" class="card-cell pointer" @click="oneClick($event, i)" @mouseenter="oneMoveIn($event, i)">
						{{ i.name }}
						<i v-show="i.children.length != 0" class="el-icon-arrow-right"></i>
					</div>
				</vue-scroll>
			</el-card>
			<el-card class="box-card" v-show="levelTwoList.length != 0">
				<!-- <div> -->
				<vue-scroll>
					<div v-for="(i, index) in levelTwoList" :key="index" class="card-cell pointer" @click="twoClick($event, i)" @mouseenter="twoMoveIn($event, i)">
						{{ i.name }}
						<i v-show="i.children.length != 0" class="el-icon-arrow-right"></i>
					</div>
				</vue-scroll>
				<!-- </div> -->
			</el-card>
			<el-card class="box-card" v-show="levelThreeList.length != 0">
				<!-- <div> -->
				<vue-scroll>
					<div v-for="(i, index) in levelThreeList" :key="index" class="card-cell card-cell-last pointer" @click="threeClick($event, i)">{{ i.name }}</div>
				</vue-scroll>
				<!-- </div> -->
			</el-card>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	props: {
		placeholder: {
			type: String,
			default: ''
		},
		listData: {
			type: Array,
			default: () => {
				return [];
			}
		},
		url: {
			type: String,
			default: '/common/ptree'
		},
		parameter: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	data() {
		return {
			list: [], //数据列表
			selectData: {}, //选中数据
			selectText: '', // 选中文字
			visible: false,
			levelTwoList: [], // 二级选择列表
			levelThreeList: [], // 三级选择列表
			mouse: false //鼠标是否在列表选择区域
		};
	},
	created() {
		if (this.listData.length != 0) {
			this.list = this.listData;
			// // console.log(this.list)
		} else {
			this.getData();
		}
	},
	mounted() {},
	computed: {
		selectList: function() {
			return this.list.filter(item => {
				if (item.name.indexOf(this.selectText) != -1) {
					return item;
				}
			});
		}
	},
	methods: {
		// 获取列表数据
		getData() {
			var data = {
				params: {
					loading: false
				}
			};
			if (JSON.stringify(this.parameter) != '{}') {
				for (let key in this.parameter) {
					data[key] = this.parameter[key];
				}
			}
			this.axios.get(this.url, data).then(res => {
				this.list = [];
				if (res.status) {
					this.list = res.data;
				}
			});
		},
		// 显示选择弹窗
		selectShow(e) {
			$('#selectionPage > .selection-card').css({
				display: 'flex'
			});
		},
		// 隐藏选择弹窗
		selectHide(e) {
			if (!this.mouse) {
				$('#selectionPage > .selection-card').css({
					display: 'none'
				});
				$('.card-cell').removeClass('card-active');
				this.levelTwoList = [];
				this.levelThreeList = [];
				if (this.selectText == '') {
					this.getAllData();
				}
			}
		},
		// 关闭选择框
		getAllData() {
			this.selectData = {};
			// 触发父组件搜索函数
			this.$emit('confirm', this.selectData);
		},
		// 移入第一层级选择区域
		oneMoveIn(e, data) {
			this.style(e);
			this.levelTwoList = [];
			this.levelThreeList = [];
			if (JSON.stringify(data) == '{}' || !data.hasOwnProperty('children')) {
				return;
			}
			if (data.children.length != 0) {
				this.levelTwoList = data.children;
			}
		},
		// 移入第二层级选择区域
		twoMoveIn(e, data) {
			this.style(e);
			this.levelThreeList = [];
			if (JSON.stringify(data) == '{}' || !data.hasOwnProperty('children')) {
				return;
			}
			if (data.children.length != 0) {
				this.levelThreeList = data.children;
			}
		},
		// 移出第一层选择区域
		onemoveIt(e) {
			this.mouse = false;
			// this.selectHide();
		},
		// 移出第二层选择区域
		towmoveIt(e) {
			this.mouse = false;
			// this.selectHide();
			this.levelTwoList = [];
			this.levelThreeList = [];
		},
		// 移出第三层选择区域
		threemoveIt(e) {
			this.mouse = false;
			// this.selectHide();
			this.levelThreeList = [];
			// console.log(123);
		},
		// 选中第一层级选择区域内容
		oneClick(e, data) {
			this.selectData = {};
			if (JSON.stringify(data) == '{}') {
				this.selectText = '全部';
			} else {
				this.selectText = data.name;
				for (let key in data) {
					// if (key.indexOf('id') != -1) {
					this.selectData[key] = data[key];
					// }
				}
			}
			this.mouse = false;
			this.selectHide();
			// 完成选择，触发父组件搜索函数
			this.$emit('confirm', this.selectData);
		},
		// 选中第二层级选择区域内容
		twoClick(e, data) {
			this.selectData = {};
			if (JSON.stringify(data) == '{}') {
				this.selectText = '全部';
			} else {
				this.selectText = data.name;

				for (let key in data) {
					// if (key.indexOf('id') != -1) {
					this.selectData[key] = data[key];
					// }
				}
			}
			this.mouse = false;
			this.selectHide();
			// 完成选择，触发父组件搜索函数
			this.$emit('confirm', this.selectData);
		},
		// 选中第三层级选择区域内容
		threeClick(e, data) {
			this.selectData = {};
			if (JSON.stringify(data) == '{}') {
				this.selectText = '全部';
			} else {
				this.selectText = data.name;
				for (let key in data) {
					// if (key.indexOf('id') != -1) {
					this.selectData[key] = data[key];
					// }
				}
			}
			this.mouse = false;
			this.selectHide();
			// 完成选择，触发父组件搜索函数
			this.$emit('confirm', this.selectData);
		},
		// 更改选中的样式
		style(e) {
			$(e.currentTarget)
				.siblings()
				.removeClass('card-active');
			$(e.currentTarget).addClass('card-active');
		},
		// 获取选中的数据并抛出
		getSelect() {
			return this.selectData;
		}
	},
	components: {},
	beforeDestroy() {},
	watch: {}
};
</script>

<style scoped lang="scss">
.selection-box {
	width: 100%;
	position: relative;

	::v-deep {
		.el-input {
			width: 100%;
		}
		.el-input__inner {
			width: 100%;
			height: 35px;
		}
	}

	.selection-card {
		position: absolute;
		top: 30px;
		left: 0;
		z-index: 1000000;
		display: none;
		// display: flex;
		justify-content: flex-start;
		align-items: center;

		.card-active {
			background-color: #ddd;
			border-radius: 6px;
		}

		.box-card {
			width: 200px;
			height: 300px;

			::v-deep {
				.el-card__body {
					width: 100%;
					height: 100%;
					padding: 0;
					font-size: 13px;

					.card-cell {
						width: calc(100% - 20px);
						word-break: break-word;
						padding: 6px 10px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin: 5px 0 5px 0;

						i {
							// float: right;
						}
					}

					.card-cell-last:hover {
						background-color: #ddd;
						border-radius: 6px;
					}
				}
			}
		}
	}
}
</style>
