<template>
  <div class="historyStaPage pageContainer">
    <div class="pageMenu">
      <ul class="nav no-select-text">
        <li @click="switchNavMenu('Curve')">曲线拟合</li>
        <li class="selected">安全预测</li>
      </ul>

      <Button v-if="selectType === 1" type="showReportLayer" :button-callback="buttonCallback" text="报告"/>
      <Button type="calculate" :button-callback="buttonCallback" text="单点估计"/>

      <div class="filtrate">
        <p>
          <span>K</span>
          <input type="number" v-model="echart.K" autocomplete="off">
        </p>
        <p>
          <span>α</span>
          <input type="number" v-model="echart.a" autocomplete="off">
        </p>
        <p>
          <span>预测</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in type"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </p>
      </div>
    </div>
    <div class="contentContainer">
      <div v-if="selectType === 1" class="containerLeft">
        <div class="filtrate">
          <p>
            <span>药量（Q）</span>
            <input type="number" v-model="echart.left.q" autocomplete="off">
            <span>Kg</span>
          </p>
          <p>
            <span>距离（R）</span>
            <input type="number" v-model="echart.left.r1" autocomplete="off">
            <span> - </span>
            <input type="number" v-model="echart.left.r2" autocomplete="off">
            <span>m</span>
          </p>
          <Button type="forecastLeft" :button-callback="buttonCallback" text="安全预测"/>
        </div>
        <div class="chartContainer">
          <div id="chartLeft"></div>
        </div>
      </div>
      <div v-if="selectType === 1" class="containerRight">
        <div class="filtrate">
          <p>
            <span>距离（R）</span>
            <input type="number" v-model="echart.right.r" autocomplete="off">
            <span>m</span>
          </p>
          <p>
            <span>药量（Q）</span>
            <input type="number" v-model="echart.right.q1" autocomplete="off">
            <span> - </span>
            <input type="number" v-model="echart.right.q2" autocomplete="off">
            <span>Kg</span>
          </p>

          <Button type="forecastRight" :button-callback="buttonCallback" text="安全预测"/>
        </div>
        <div class="chartContainer">
          <div id="chartRight"></div>
        </div>
      </div>
      <div v-if="selectType === 2" class="containerOther">
        <div class="filtrate">
          <p>
            <span>振动（v）</span>
            <input type="number" v-model="echart.outer.v" autocomplete="off">
            <span>cm/s</span>
          </p>
          <p>
            <span>距离（R）</span>
            <input type="number" v-model="echart.outer.r1" autocomplete="off">
            <span> - </span>
            <input type="number" v-model="echart.outer.r2" autocomplete="off">
            <span>m</span>
          </p>

          <Button type="forecastOther" :button-callback="buttonCallback" text="安全预测"/>
        </div>
        <div class="chartContainer">
          <div id="chartOther"></div>
        </div>
      </div>
    </div>

    <!--  报告  -->
    <forecastReport ref="forecastReport"/>

    <!--  弹出框  -->
    <LayerContainer>
      <LayerItem :layer-param="reportLayer" :layer-btn-click="layerBtnClick">
        <reportLayer ref="reportLayer"/>
      </LayerItem>
      <LayerItem :layer-param="calculateLayer" :layer-btn-click="layerBtnClick">
        <calculateLayer ref="calculateLayer"/>
      </LayerItem>
    </LayerContainer>
  </div>
</template>

<script>
  import forecast from "./forecast.js"
  export default forecast;
</script>

<style scoped lang="scss">
  @import "./forecast.scss";
</style>

