<template>
	<div class="animationContainer">
		<Curve :switchNavMenu="switchNavMenu" v-show="page === 'Curve'" ref="Curve"></Curve>
		<Forecast :switchNavMenu="switchNavMenu" v-show="page === 'Forecast'" ref="Forecast"></Forecast>
	</div>
</template>

<script>
import Curve from './curve/curve.vue';
import Forecast from './forecast/forecast.vue';

export default {
	name: 'analysis',
	components: { Curve, Forecast },
	data() {
		return {
			page: 'Curve'
		};
	},
	methods: {
		switchNavMenu(type) {
			this.page = type;
			if (type === 'Forecast') {
				let condition = this.$refs.Curve.condition;
				this.$refs.Forecast.initChart(condition);
			}
		},
		//获取曲线拟合中选中的数据
		getSelectedFile() {
			let file = this.$refs.Curve.checkedFileList;
			let type = this.$refs.Curve.value;
			let data = {};
			if (!file.length) {
				this.$messages('您没有选择分析的数据！', 'warning');
				return false;
			}
			data.companyName = file[0].companyName;
			data.pointName = file[0].pointName;
			data.projectName = file[0].projectName;
			data.operator = file[0].operator ? file[0].operator : null;
			data.tableData = [];
			data.date = [];
			data.datet = [];
			for (let i = 0; i < file.length; i++) {
				let str = file[i].fileName;
				if (str != undefined) {
					let year = str.slice(0, 4);
					let month = str.slice(4, 6);
					let day = str.slice(6, 8);
					let hour = str.slice(8, 10);
					let minute = str.slice(10, 12);
					let second = str.slice(12, 14);
					str = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
					data.date.push(str);
				} else {
					data.date.push(new Date().format('yyyy-MM-dd hh:mm:ss'));
					data.datet.push(
						new Date()
							.format('yyyy-MM-dd hh:mm:ss')
							.split('-')
							.join('')
							.split(':')
							.join('')
							.replace(' ', '') + '.bin'
					);
				}

				data.tableData.push({
					filename: file[i].fileName,
					filenames: data.datet[i],
					qVal: file[i].Q,
					rVal: file[i].R,
					vVal: Number(file[i][type + 'max']).toFixed(4),
					tVal: Number(file[i][type + 'maxTime']).toFixed(4)
				});
			}
			return data;
		}
	}
};
</script>

<style scoped lang="scss">
.analysisPage {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
}
</style>
