<template>
  <div class="calculateLayer">
    <div class="basics infoItem">
      <div class="infoContent">
        <el-scrollbar :native="false" :noresize="false" tag="div">
          <label class="list">
            <span>α</span>
            <input type="text" v-model="A" placeholder="请输入项目名称" autocomplete="off">
          </label>
          <label class="list">
            <span>K</span>
            <input type="text" v-model="K" placeholder="请输入测点编号" autocomplete="off">
          </label>
          <label class="list">
            <span>药量(Q)</span>
            <input type="text" v-model="Q" placeholder="请输入测点编号" autocomplete="off">
            <em>kg</em>
          </label>
          <label class="list">
            <span>距离(R)</span>
            <input type="text" v-model="R" placeholder="请输入操作人员" autocomplete="off">
            <em>m</em>
          </label>
          <label class="list">
            <span>速度(V)</span>
            <input type="text" readonly :value="V" placeholder="请输入参数计算" autocomplete="off">
            <em>cm/s</em>
          </label>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "calculateLayer",
  data() {
    return {
      A: 0,
      K: 0,
      Q: 0,
      R: 0,
    }
  },
  computed: {
    V() {
      if (this.Q && this.R && this.K && this.A) {
        return this.toFixed(this.Sadowski.calculateV(this.Q, this.R, this.K, this.A), 4);
      }
      return this.A
    }
  },
  methods: {
    //显示弹窗
    showLayer(K, A) {
      this.K = K;
      this.A = A;
      $('.layerContainerBox').fadeIn(300).find('.calculate').show().siblings().hide();
    },
  },
}
</script>

<style scoped lang="scss">
.calculateLayer {
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  overflow: hidden;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;

  .infoItem {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    width: 100%;

    .title {
      flex: 0 0 20px;
      line-height: 20px;
      color: #3B9EFF;
    }

    .infoContent {
      flex: 1 1 auto;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-flow: column;


      .list {
        width: 50%;
        line-height: 50px;
        float: left;

        span {
          float: left;
          width: 50px;
          text-align: right;
          margin-right: 10px;
          color: #4A4A4A;
        }

        input {
          height: 28px;
          padding: 0 10px;
          box-sizing: border-box;
          border: 1px solid #E5E5E5;
          font-size: 12px;
        }
      }

      .list:last-child {
        margin-bottom: 10px;
      }

      .list.end {
        width: 100%;
        overflow: hidden;
        margin-left: 0;

        input {
          width: 50px;
        }

        .el-radio {
          min-width: 150px;
        }

        > > > .el-select {
          .el-input__inner {
            width: 475px;
          }
        }

        ul {
          border: 1px solid #ccc;
          background-color: #f5f5f5;
          overflow: hidden;
          height: 50px;

          li {
            float: left;
            width: 25%;
            height: 50px;
            line-height: 25px;
            border-right: 1px solid #ccc;
            box-sizing: border-box;
            text-align: center;
          }

          li:first-child {
            line-height: 50px;
          }

          li:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
}
</style>
