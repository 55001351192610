<template>
	<div class="historyStaPage pageContainer">
		<div class="pageMenu">
			<ul class="nav no-select-text">
				<li class="selected">曲线拟合</li>
				<li @click="switchNavMenu('Forecast')">安全预测</li>
			</ul>

			<Button type="search" :button-callback="buttonCallback" text="查询" />
			<div class="screen time">
				<span>时段：</span>
				<el-date-picker v-model="form.timeS" type="datetime" :clearable="false" value-format="timestamp" placeholder="选择日期时间"></el-date-picker>
				<span>至</span>
				<el-date-picker v-model="form.timeE" type="datetime" :clearable="false" value-format="timestamp" placeholder="选择日期时间"></el-date-picker>
			</div>

			<div class="screen">
				<span>类型：</span>
				<el-select v-model="form.type" filterable placeholder="请选择设备类型">
					<el-option key="0" label="全部" value="0"></el-option>
					<el-option v-for="item in deviceType" :key="item.id" :label="item.deviceType" :value="item.id"></el-option>
				</el-select>
			</div>

			<!-- <myCascader
        :change="changeValue"
        :options="tree"
        ref="myCascader"
        placeholder="请选择组织机构"
      ></myCascader> -->
			<div class="screen"><selection ref="myCascader" placeholder="请选择组织机构"></selection></div>
		</div>
		<div class="contentContainer">
			<div class="fileContainer">
				<div class="filtrate"><Button type="addList" :button-callback="buttonCallback" text="添加" /></div>
				<div class="tableHeader no-select-text">
					<span style="width: 5%;"><i @click="selectAllDevice" :class="getCheckedStatus(checkAllStatus)"></i></span>
					<span style="width: 10%;">序号</span>
					<span style="width: 15%;">测点编号</span>
					<span style="width: 25%;">文件名</span>
					<span style="width: 15%;">幅值（X）</span>
					<span style="width: 15%;">幅值（Y）</span>
					<span style="width: 15%;">幅值（Z）</span>
				</div>

				<div class="tableContainer">
					<div class="tableContent">
						<noData v-if="!item.total" />
						<el-scrollbar :native="false" :noresize="false" tag="div" v-if="item.total">
							<ul class="tableList" v-for="(p, i) in item.rows" :key="i">
								<li class="no-select-text" style="width: 5%;"><i @click="selectDevice(p)" :class="p.selected ? 'checkbox is-checked' : 'checkbox'"></i></li>
								<li style="width: 10%;">{{ getListNumber(i, item) }}</li>
								<li style="width: 15%;" :title="p.pointName">{{ p.pointName }}</li>
								<li style="width: 25%;" :title="formatFileName(p.fileName)">{{ formatFileName(p.fileName) }}</li>
								<li style="width: 15%;" :title="toFixed(p.xmax, 4)">{{ toFixed(p.xmax, 4) }}</li>
								<li style="width: 15%;" :title="toFixed(p.ymax, 4)">{{ toFixed(p.ymax, 4) }}</li>
								<li style="width: 15%;" :title="toFixed(p.zmax, 4)">{{ toFixed(p.zmax, 4) }}</li>
							</ul>
						</el-scrollbar>
					</div>

					<Pagination :item="item" :current-page-change="currentPageChange"></Pagination>
				</div>
			</div>
			<div class="selectContainer">
				<div class="filtrate">
					<span>方向</span>
					<el-select v-model="value" placeholder="请选择">
						<el-option v-for="(item, i) in options" :key="i" :label="item.label" :value="item.value"></el-option>
					</el-select>
					<Button type="clearAll" class="clear" :button-callback="buttonCallback" text="清空" />
					<Button type="addData" class="addData" :button-callback="buttonCallback" text="数据录入" />
				</div>

				<div class="tableHeader no-select-text">
					<span style="width: 7%;">序号</span>
					<span style="width: 15%;">测点编号</span>
					<span style="width: 25%;">文件名</span>
					<span style="width: 11%;">振动峰值V（X）</span>
					<span style="width: 11%;">峰值时刻（Y）</span>
					<span style="width: 10%;">药量（Q）</span>
					<span style="width: 8%;">距离（R）</span>
					<span style="width: 10%;text-align: center;text-indent: 0">编辑</span>
				</div>

				<div class="tableContainer">
					<div class="tableContent">
						<noData v-if="!checkedFileList.length" />
						<el-scrollbar :native="false" :noresize="false" tag="div" v-if="checkedFileList.length">
							<ul class="tableList no-select-text" v-for="(p, i) in checkedFileList" :key="i">
								<li style="width: 7%;">{{ i + 1 }}</li>
								<li style="width: 15%;" :title="p.pointName">{{ p.pointName ? p.pointName : '-' }}</li>
								<li style="width: 25%;" class="fileName" @dblclick="changeFileValue" :title="p.fileName">
									<span>{{ p.fileName }}</span>
									<input @blur="hiddenValue" hidden type="text" v-model="p.alias" />
								</li>
								<li style="width: 11%;" :title="Number(p[value + 'max']).toFixed(4)">{{ Number(p[value + 'max']).toFixed(4) }}</li>
								<li style="width: 11%;" :title="Number(p[value + 'maxTime']).toFixed(4)">{{ Number(p[value + 'maxTime']).toFixed(4) }}</li>
								<!--<li style="width: 13%;" :title="Number(p.ymax).toFixed(4)">{{Number(p.ymax).toFixed(4)}}</li>-->
								<li style="width: 10%;" class="fileName" @dblclick="changeFileValue">
									<span>{{ p.Q }}</span>
									<input @blur="hiddenValue" hidden type="text" v-model="p.Q" />
								</li>
								<li style="width: 8%;" class="fileName" @dblclick="changeFileValue">
									<span>{{ p.R }}</span>
									<input @blur="hiddenValue" hidden type="text" v-model="p.R" />
								</li>
								<li style="width: 10%;" class="compile"><i @click="clearData(i)" class="el-icon-delete delete red"></i></li>
							</ul>
						</el-scrollbar>
					</div>
				</div>

				<div class="fitting">
					<div class="condition">
						<div class="item">
							<span>计算依据</span>
							<p>爆破安全规程GB6722/萨道夫斯基公式</p>
						</div>
						<div class="item">
							<span>k</span>
							<input type="text" :value="condition.k" />
						</div>
						<div class="item">
							<span>α</span>
							<input type="text" :value="condition.a" />
						</div>

						<Button type="createChart" :button-callback="buttonCallback" text="曲线拟合" />
					</div>
					<div id="chartContainer"></div>
				</div>
			</div>
		</div>

		<LayerContainer>
			<LayerItem :layer-param="layerParam" :layer-btn-click="layerBtnClick">
				<div class="addDataLayer">
					<div class="basics infoItem">
						<div class="infoContent">
							<label class="list">
								<em>药量(Q)</em>
								<input type="number" v-model="addData.Q" placeholder="请输入药量" autocomplete="off" />
								<i>kg</i>
							</label>
							<label class="list">
								<em>距离(R)</em>
								<input type="number" v-model="addData.R" placeholder="请输入距离" autocomplete="off" />
								<i>m</i>
							</label>
							<label class="list">
								<em>速度(V)</em>
								<input type="number" v-model="addData.V" placeholder="请输入速度" autocomplete="off" />
								<i>cm/s</i>
							</label>
						</div>
					</div>
				</div>
			</LayerItem>
		</LayerContainer>
	</div>
</template>

<script>
import curve from './curve.js';

export default curve;
</script>

<style scoped lang="scss">
@import 'curve';
</style>
