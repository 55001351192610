export default () => {
  return {
    checkedFile: [],
    checkedFileList: [],
    checkAll: false,
    checkAllStatus: 0,
    searchType: {
      id: 1,
      name: '企业'
    },
    placeholder: '企业',
    tree: [],
    enterprise: [],
    project: [],
    stationLine: [],
    station: [],
    deviceType: [],
    layerParam: {
      title: '数据录入',
      itemClass: 'addData data',
      confirmBtn: '确定',
      cancelBtn: '取消',
      confirmType: 'dataCon',
      cancelType: 'dataCan',
      hintText: '',
      hintColor: '#f00',
    },
    addData: {
      V: '',
      Q: '',
      R: ''
    },
    item: {
      rows: [],
      page: 1,
      size: 30,
      allPage: 0,
      total: 0,
      type: 'curve',
      dataType: 'list',
      time: false,
      text: '暂时没有数据哦--',
    },
    form: {
      organization: null,
      type: null,
      station: null,
      timeS: '',
      timeE: '',
    },
    condition: {
      k: 0,
      a: 0
    },
    options: [{
      value: 'x',
      label: '径向（X）'
    }, {
      value: 'y',
      label: '垂向（Y）'
    }, {
      value: 'z',
      label: '切向（Z）'
    }, {
      value: 'sum',
      label: '矢量合成'
    }],
    value: 'x',
    echart: {
      options: {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '30',
          top: '30',
          right: '30',
          bottom: '30',
          containLabel: true
        },
        xAxis: {
          min: 0,
          type: 'category',
          data: ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100'],
          name: 'Q^(1/3)/R',
          nameLocation: 'center',
          nameGap: '30',
          axisLine: {onZero: false},
          nameTextStyle: {
            fontWeight: 'bold',
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#ccc'
            }
          },
          axisLabel: {
            formatter: function (value) {
              return Number(value).toFixed(1);
            }
          }
        },
        yAxis: {
          min: 0,
          max: 10,
          name: 'V(cm/s)',
          nameLocation: 'center',
          nameGap: '30',
          type: 'log',
          axisLine: {onZero: false},
          axisLabel: {
            formatter: function (value) {
              return Number(value.toFixed(2));
            }
          },
          nameTextStyle: {
            fontWeight: 'bold',
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#ccc'
            }
          }
        },
        series: []
      }
    }
  }
}
