export default () => {
  return {
    echart: {
      K: 0,
      a: 0,
      left: {
        q: 20,
        r1: 20,
        r2: 100,
        dom: null,
      },
      right: {
        r: 50,
        q1: 20,
        q2: 100,
        dom: null,
      },
      outer: {
        v: 20,
        r1: 5,
        r2: 50
      },
      options: {
        tooltip: {
          trigger: 'axis',
          formatter: (data) => {
            data = data[0];
            return `<div>
                  <h5 style="font-size: 14px;font-weight: normal">${data.axisValue}</h5>
                  <p>${data.marker}${data.value}cm/s</p>
               </div>`
          }
        },
        grid: {
          left: '30',
          top: '30',
          right: '30',
          bottom: '30',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100'],
          name: 'a(1/3)/R',
          nameLocation: 'center',
          nameGap: '30',
          nameTextStyle: {
            fontWeight: 'bold',
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#ccc'
            }
          },
        },
        yAxis: {
          min: 0,
          max: 100,
          name: 'V(cm/s)',
          nameLocation: 'center',
          nameGap: '30',
          type: 'value',
          nameTextStyle: {
            fontWeight: 'bold',
            fontSize: 14
          },
          axisLabel: {
            formatter: function (value) {
              return value.toFixed(2);
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#ccc'
            }
          }
        },
        series: []
      }
    },
    type: [{
      value: '1',
      label: '药量/距离'
    }, {
      value: '2',
      label: 'Q/R关系图'
    }],
    value: '1',
    selectType: 1,
    selectedFile: [],
    reportLayer: {
      title: '报告信息',
      itemClass: 'report',
      confirmBtn: '确定',
      cancelBtn: '取消',
      confirmType: 'reportCon',
      cancelType: 'reportCan',
      hintText: '',
      hintColor: '#f00',
    },
    calculateLayer: {
      title: '单点估计',
      itemClass: 'calculate',
      cancelBtn: '取消',
      cancelType: 'calculateCan',
      hiddenConfirm: true,
      hintText: '',
      hintColor: '#f00',
    },
  }
}
