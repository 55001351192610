import data from "./forecastReportData.js"
import GitChartImg from "../../../assets/docxtemplater/GitChartImg";
import '../../../assets/docxtemplater/pizzip'
import '../../../assets/docxtemplater/docxtemplater'
import '../../../assets/docxtemplater/FileSaver'
import '../../../assets/docxtemplater/pizzip-utils'


export default {
	name: "forecastReport",
	data,
	created() {

	},
	computed: {},
	methods: {
		exportToPDF() {
			//判断iframe是否存在，不存在则创建iframe
			let iframe = document.getElementById("print-iframe");
			let el = document.getElementById("briefReport");
			let doc = null;

			if (!iframe) {
				iframe = document.createElement('IFRAME');
			}

			iframe.setAttribute("id", "print-iframe");
			iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:-500px;top:-500px;');
			document.body.appendChild(iframe);
			doc = iframe.contentWindow.document;
			//这里可以自定义样式
			// doc.write('<LINK rel="stylesheet" type="text/css" href="/static/forecastReport.css">');
			doc.write('<div>' + el.innerHTML + '</div>');
			doc.close();
			iframe.contentWindow.focus();

			iframe.contentWindow.print();
			if (navigator.userAgent.indexOf("MSIE") > 0) {
				document.body.removeChild(iframe);
			}
		},
		base64DataURLToArrayBuffer(dataURL) {
			const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
			if (!base64Regex.test(dataURL)) {
				return false;
			}
			const stringBase64 = dataURL.replace(base64Regex, "");
			let binaryString;
			if (typeof window !== "undefined") {
				binaryString = window.atob(stringBase64);
			} else {
				binaryString = new Buffer(stringBase64, "base64").toString("binary");
			}
			const len = binaryString.length;
			const bytes = new Uint8Array(len);
			for (let i = 0; i < len; i++) {
				bytes[i] = binaryString.charCodeAt(i);
			}
			return bytes.buffer;
		},
		// 点击导出word
		exportWord: function () {
			const ImageModule = require('docxtemplater-image-module-free');
			// // console.log(ImageModule);
			let _this = this;
			// 读取并获得模板文件的二进制内容
			PizZipUtils.getBinaryContent(window.location.origin + "/static/forecast.docx", function (error, content) {
				// wordTemplate.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
				// 抛出异常
				if (error) {
					throw error;
				}
				// 图片处理
				let opts = {};
				opts.centered = true;  // 图片居中，在word模板中定义方式为{%%image}
				opts.fileType = "docx";
				opts.getImage = function (tagValue) {
					return _this.base64DataURLToArrayBuffer(tagValue);
				};
				opts.getSize = function () {
					return [450, 300]
				};
				let imageModule = new ImageModule(opts);

				// 创建一个JSZip实例，内容为模板的内容
				let zip = new PizZip(content);
				// 创建并加载docxtemplater实例对象
				let doc = new window.docxtemplater();
				doc.loadZip(zip);
				doc.attachModule(imageModule);


				// 设置模板变量的值
				doc.setData({
					..._this.form,
					table: _this.tableData,
				});

				try {
					// 用模板变量的值替换所有模板变量
					doc.render();
				} catch (error) {
					// 抛出异常
					let e = {
						message: error.message,
						name: error.name,
						stack: error.stack,
						properties: error.properties
					};
					// console.log(JSON.stringify({ error: e }));
					throw error;
				}

				// 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
				let out = doc.getZip().generate({
					type: "blob",
					mimeType:
						"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
				});
				// 将目标文件对象保存为目标类型的文件，并命名
				saveAs(out, _this.form.name + ".docx");
			});
		},
		//隐藏弹窗
		closeLayer() {
			$('.briefReportPage').fadeOut(300)
		},
		//显示弹窗
		showLayer() {
			$('.briefReportPage').fadeIn(300);
		},
		//获取绘制好的chart
		async getChartImage(selectFile) {
			if(selectFile != undefined){
				this.form.Q = this.$parent.echart.left.q;
				this.form.R = this.$parent.echart.right.r;
				this.form.K = this.$parent.echart.K;
				this.form.a = this.$parent.echart.a;
	
				this.form.safetyQ = this.Sadowski.calculateQ(this.form.CVal, this.form.R, this.form.K, this.form.a);
				this.form.safetyR = this.Sadowski.calculateR(this.form.CVal, this.form.Q, this.form.K, this.form.a);
	
				this.form.safetyQ = Number(this.form.safetyQ.toFixed(4))
				this.form.safetyR = Number(this.form.safetyR.toFixed(4))
				this.form.dataLength = selectFile.tableData.length;
				this.form.project = selectFile.projectName;
				this.form.enterprise = selectFile.companyName;
				this.form.operator = selectFile.operator;
				this.form.remarks = selectFile.remarks;
				this.form.date = selectFile.date;
				this.tableData = selectFile.tableData;
				//   // console.log(selectFile,"huiqweqweqwe");
	
				let { curve, left, right } = this.$session.get().analysis;
				let img = this.createECharts([curve, left, right]);
				img.then(res => {
					this.form.curve = res[0];
					this.form.left = res[1];
					this.form.right = res[2];
					this.showLayer()
				})
			}
		},
		//创建三个容器储存echarts
		createECharts(options) {
			const container1 = document.createElement('div');
			const container2 = document.createElement('div');
			const container3 = document.createElement('div');
			let images = [];

			return new Promise((resolve, reject) => {
				[container1, container2, container3].map((item, index) => {
					let option = options[index];
					item.style.width = '500px';
					item.style.height = '300px';
					let Dom = echarts.init(item);
					if (option && typeof option === "object") {
						option.animation = false;
						Dom.clear();
						Dom.setOption(option, true);
						images.push(Dom.getDataURL());

						if (index === 2) {
							resolve(images)
						}
					} else {
						this.$messages('请先生成图表', 'warning');
					}
				});
			})
		},
		//创建图片
		createImage(left, right) {
			const images = [left, right];
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			canvas.width = 0;
			canvas.height = 0;

			return new Promise(resolve => {
				images.map((item, index) => {
					const img = new Image;
					img.src = item;
					img.onload = function () {
						if (!canvas.width) {
							canvas.width = img.width * 2;
							canvas.height = img.height;
							canvas.style.background = '#fff';
							ctx.fillStyle = '#fff';
							ctx.fillRect(0, 0, canvas.width, canvas.height);
						}
						ctx.drawImage(img, index * img.width, 0, img.width, img.height);
						if (index === 1) {
							resolve(canvas.toDataURL('images/png'))
						}
					}
				});
			})
		},
		//压缩图片大小
		CompressImages(base64) {
			const img = new Image();
			const canvas = document.createElement('canvas');
			const ctx = canvas.getContext('2d');
			img.src = base64;
			return new Promise((resolve, reject) => {
				img.onload = function () {
					canvas.width = img.width;
					canvas.height = img.height;
					canvas.style.background = '#fff';
					ctx.fillStyle = '#fff';
					ctx.fillRect(0, 0, canvas.width, canvas.height);
					ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
					let base64 = canvas.toDataURL('image/jpeg', 1);
					base64 = base64.replace(/data:image\/jpeg/, 'data:image/png');
					resolve(base64)
				}
			})
		}
	},
	mounted() {
		this.getChartImage();
	},
	beforeDestroy() {
	},
	watch: {}
}
