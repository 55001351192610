<template>
  <div class="briefReportPage">
    <div id="tableContainer">
      <el-scrollbar :native="false" :noresize="false" tag="div">
        <div class="tableWidth" id="briefReport">
          <h3 style="text-align: center;font-size: 20px;margin-top: 10px">爆破安全药量预测报告</h3>
          <h5 style="text-align: center;font-size: 16px;margin-bottom: 10px;margin-bottom: 20px">衰减计算报告</h5>
          <table border="0" cellspacing="0" cellpadding="0" style="border-top: 1px solid #000;border-left: 1px solid #000;width: 100%">
            <tr style="line-height: 30px">
              <td style="width: 20%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                工程项目</td>
              <td style="width: 80%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                {{form.project}}</td>
            </tr>
            <tr style="line-height: 30px">
              <td style="width: 20%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                监测公司</td>
              <td style="width: 80%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                {{form.enterprise}}</td>
            </tr>
            <tr style="line-height: 30px">
              <td style="width: 20%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                操作人员</td>
              <td style="width: 80%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                {{form.operator}}</td>
            </tr>
            <tr style="line-height: 30px">
              <td style="width: 20%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                测试时间</td>
              <td style="width: 80%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                {{form.date.toString()}}</td>
            </tr>
            <tr style="line-height: 30px">
              <td style="width: 20%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                测试数据</td>
              <td style="width: 80%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                {{form.dataLength}}</td>
            </tr>
            <tr style="line-height: 30px">
              <td style="width: 20%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                计算依据</td>
              <td style="width: 80%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                <p>萨道夫斯基回归公式</p>
                <p><img src="/static/images/sadowski.png" alt="icon"></p>
                <p>R--爆破振动安全允许距离：m</p>
                <p>Q--炸药量，齐发爆破为总药量，延时爆破为最大单段药量：kg</p>
                <p>V--保护对象所在地安全允许质点振速：cm/s</p>
                <p>K,α--与爆破点至保护对象间的地形、地质条件有关的系数和衰减指数</p>
              </td>
            </tr>
            <tr style="line-height: 30px">
              <td style="width: 20%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                计算结果</td>
              <td style="width: 80%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                <img :src="form.curve" alt="计算结果">
                <p>K：{{form.K}}&emsp;&emsp;a：{{form.a}}</p>
              </td>
            </tr>
            <tr style="line-height: 30px">
              <td style="width: 30%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                安全药量预测</td>
              <td style="width: 70%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                <img :src="form.right" alt="安全药量预测">
                <p>距爆源{{form.R}}m的建构物，其控制值是{{form.CVal}}cm/s时，安全药量为{{form.safetyQ}}kg</p>
              </td>
            </tr>
            <tr style="line-height: 30px">
              <td style="width: 30%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                安全距离预测</td>
              <td style="width: 70%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                <img :src="form.left" alt="安全距离预测">
                <p>炸药量为{{form.Q}}kg的爆破,控制值是{{form.CVal}}/s时，安全距离为{{form.safetyR}}m</p>
              </td>
            </tr>
            <tr style="line-height: 30px">
              <td style="width: 30%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                备注</td>
              <td style="width: 70%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                {{form.remarks}}</td>
            </tr>
          </table>

<!--          <div class="chart" style="margin: 20px 0">-->
<!--            <img :src="form.images" alt="img" style="width: 100%">-->
<!--          </div>-->

          <table border="0" cellspacing="0" cellpadding="0" style="border-top: 1px solid #000;border-left: 1px solid #000;width: 100%;text-align: center;margin-top: 100px">
            <tr style="line-height: 30px">
              <td style="width: 28%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                文件名</td>
              <td style="width: 18%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                <p>药量</p>
                <p>Q(Kg)</p></td>
              <td style="width: 18%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                <p>距离</p>
                <p>R(m)</p></td>
              <td style="width: 18%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                <p>振动峰值</p>
                <p>V(cm/s)</p></td>
              <td style="width: 18%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                <p>振动时刻</p>
                <p>T(s)</p></td>
            </tr>
            <tr v-for="(f,i) in tableData" style="line-height: 30px" :key="i">
              <td style="width: 28%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                <span v-if="f.filenames">{{f.filenames}}</span><span v-else>{{f.filename}}</span></td>
              <td style="width: 18%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                {{f.qVal}}</td>
              <td style="width: 18%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                {{f.rVal}}</td>
              <td style="width: 18%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                {{f.vVal}}</td>
              <td style="width: 18%;border-bottom: 1px solid #000;border-right: 1px solid #000;box-sizing: border-box;padding: 0 10px;">
                {{f.tVal}}</td>
            </tr>
          </table>
        </div>
        <div class="btnContainer">
          <p @click="exportToPDF">
            <i class="icon iconfont icon-daochu1"></i>
            <span>导出PDF</span>
          </p>
          <p @click="exportWord">
            <i class="icon iconfont icon-daochu1"></i>
            <span>导出word</span>
          </p>
          <p @click="closeLayer">
            <i class="icon iconfont icon-quxiao"></i>
            <span>取消</span>
          </p>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
  import briefReport from "./forecastReport.js"

  export default briefReport;
</script>

<style scoped lang="scss">
  @import "forecastReport";
</style>

